<!--
 * @Author: li 1179378299@qq.com
 * @Date: 2022-12-09 11:07:27
 * @LastEditors: li 1179378299@qq.com
 * @LastEditTime: 2023-01-10 17:00:17
 * @FilePath: \datav-screen\src\components\topHeard\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="center">
      <div class="content">
        <div class="logo">
          <img src="https://pic.tralife.cn/10001/20230206/a908ba16371efd95377aa1548017cba5.png"/>
        </div>
        <div class="title">数据可视化大屏(门店版)</div>
      </div>
      <div class="time">
         {{dataTime.val}}
      </div>
    </div>
</template>

<script>
	import { formatTime } from '@/utils/index.js'
export default {
    data() {
        return {
			dataTime: {
			  val: "",
			  timer: null
			},
        }
    },
    methods: {
		// 获取时间
		getDateTime() {
		  this.dataTime.val = formatTime(new Date(), 'HH:mm:ss yyyy/MM/dd');
		  this.dataTime.timer = setInterval(() => {
		    this.dataTime.val = formatTime(new Date(), 'HH:mm:ss yyyy/MM/dd');
		  }, 1000)
		},
		clearTimer() {
		  if (this.dataTime.timer) {
		    clearInterval(this.dataTime.timer);
		    this.dataTime.timer = null;
		  }
		},
    },
    mounted() {
		this.getDateTime()
    },
	beforeDestroy() {
	  clearInterval(this.dataTime.timer);
	}
}
</script>

<style scoped lang="scss">
	@import "./index.scss";
</style>
