<template>
	<div class="wrap">
		<div class="model">
			<div id="chart" style="width: 97px; height: 97px;"></div>
			<div class="text-box">
				<div class="text-num">{{storeNum}}</div>
				<div class="text">新增门店</div>
			</div>
		</div>
		<div class="model">
			<div id="orderNum" style="width: 97px; height: 97px;"></div>
			<div class="text-box">
				<div class="text-num">{{orderNum}}</div>
				<div class="text">订单数</div>
			</div>
		</div>
		<div class="model">
			<div id="consume" style="width: 97px; height: 97px;"></div>
			<div class="text-box">
				<div class="text-num">{{price}}</div>
				<div class="text">消费金额</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as storeDay from '../../api/screen.js';
	let Echarts = require('echarts/lib/echarts'); //基础实例 注意不要使用import
	require('echarts/lib/chart/bar'); //按需引入 bar = 柱状图
	export default {
		props: {
			allPayNum: {
				type: [String, Number],
				default: 0
			},
			allPaySum: {
				type: [String, Number],
				default: 0
			},
			shop_all: {
				type: [String, Number],
				default: 0
			}
		},
		data() {
			return {
				consumeNum: null,
				storeCharts: null,
				orderCharts: null,
				// shop_all: 0, //门店总数
				echartsSumnum: 0, //数据总数
				purchase: 0, //消费金额30日占比
				use: 0, //消费金额30日环形图带颜色的
				part: 0, //消费金额30日环形图另一部分
				storeNum: 0, //30新增门店单位
				price: 0, //30日消费金额
				orderNum: 0, //30日订单数
				addstore: 0, //30日新增门店
				props: 0, //30日新增门店占比
				half: 0, //30日新增门店占比另一部分
				count: 0,//环形图带颜色的订单数
				radio: 0,//占比
				remain: 0, //环形图另一部分
			}
		},
		watch: {
			propsData(val) {
				const {allPayNum, allPaySum, shop_all} = val;
				if (allPayNum && allPaySum && shop_all) {
					setTimeout(() => {
						this.day();
					},500)
					this.myVar = setInterval(() => {
						this.day();
					}, 3600000)
				}
			}
		},
		computed: {
			propsData() {
				return {
					allPayNum: this.allPayNum,
					allPaySum: this.allPaySum,
					shop_all: this.shop_all
				}
			}
		},
		methods: {
			// 门店总数
			// total() {
			// 	storeDay.showData({
			// 			name: 'shop_all'
			// 		})
			// 		.then(res => {
			// 			let data = res.data.data;
			// 			this.shop_all = data.shop_all;
			// 			this.day();
			// 		})
			// },
			// 30日消费金额数据
			day() {
				storeDay.showData({
						name: 'Statistics_30_day_new'
					})
					.then(res => {
						let data = res.data.data;
						// 30日消费金额
						this.purchase = parseInt((data.shop_30amount - 100000000) / this.allPaySum * 100); //占比
						this.use = data.shop_30amount; //环形图带颜色的
						this.part = this.allPaySum - data.shop_30amount; //环形图另一部分

						// 30日门店金额
						this.addstore = data.shop_30day; //环形图带颜色的门店数
						this.props = parseInt(parseInt(this.addstore) / parseInt(this.shop_all) *
							100); //占比
						this.half = this.shop_all - this.addstore; //环形图另一部分
						
						// 30日订单数
						this.radio = parseInt(parseInt(data.shop_30order) / parseInt(this.allPayNum) * 100); //占比
						this.count = data.shop_30order; //环形图带颜色的订单数
						this.remain = this.allPayNum - data.shop_30order; //环形图另一部分

						// 判断数据以万忆为单位
						let func = (value) => {
							let count = parseInt(value).toString();
							if (count.length > 4 && count.length < 9) {
								return `${(count / 10000).toFixed(2)}万`;
							} else if (count.length >= 9) {
								return `${(count / 100000000).toFixed(2)}亿`;
							} else {
								return value
							}
						}
						this.price = func((data.shop_30amount - 100000000).toString()); //消费金额
						this.orderNum = func((data.shop_30order).toString());//订单数
						this.storeNum = func(data.shop_30day); //新增门店
						this.addStore();
						this.order();
						this.expend();
					})
			},
			// 新增门店
			addStore() {
				//2.初始化
				const that = this;
				let myChart = document.getElementById('chart');
				this.storeCharts = Echarts.init(myChart);
				//3.配置数据
				let option = {
					tooltip: {
						trigger: 'item',
						show: false,
					},
					graphic: [{ //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: 'center',
						style: {
							text: [
								`${this.props}%`,
								`占比`,
							].join('\n'),
							textAlign: 'center',
							fill: '#fff', //文字的颜色 
							fontSize: 14,
						}
					}, ],
					color: ['#29CC8D', '#272244'],
					series: [{
						// name: '访问来源',
						type: 'pie',
						hoverAnimation: false,
						radius: ['100%', '80%'],
						center: ['50%', '50%'],
						label: {
							normal: {
								show: false,
								position: 'center'
							}
						},
						data: [{
							value: this.addstore
						}, {
							value: this.half
						}],
					}]
				};
				// 4.传入数据
				this.storeCharts.setOption(option);
			},
			// 订单数
			order() {
				//2.初始化
				const that = this;
				let myChart = document.getElementById('orderNum');
				this.orderCharts = Echarts.init(myChart);
				//3.配置数据
				let option = {
					tooltip: {
						trigger: 'item',
						show: false,
					},
					graphic: [{ //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: 'center',
						style: {
							text: [
								`${this.radio}%`,
								`占比`,
							].join('\n'),
							textAlign: 'center',
							fill: '#fff', //文字的颜色 
							fontSize: 14,
						}
					}, ],
					color: ['#1876F1', '#272244'],
					series: [{
						// name: '访问来源',
						type: 'pie',
						hoverAnimation: false,
						radius: ['100%', '80%'],
						center: ['50%', '50%'],
						label: {
							normal: {
								show: false,
								position: 'center'
							}
						},
						data: [{
							value: this.count
						}, {
							value: this.remain
						}],
					}]
				};
				// 4.传入数据
				this.orderCharts.setOption(option);
			},

			// 30日消费金额数据
			expend() {
				//2.初始化
				const that = this;
				let myChart = document.getElementById('consume');
				this.consumeNum = Echarts.init(myChart);
				//3.配置数据
				let option = {
					tooltip: {
						trigger: 'item',
						show: false,
					},
					graphic: [{ //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: 'center',
						style: {
							text: [
								`${this.purchase}%`,
								`占比`,
							].join('\n'),
							textAlign: 'center',
							fill: '#fff', //文字的颜色 
							fontSize: 14,
						}
					}, ],
					color: ['#FFDB64', '#272244'],
					series: [{
						// name: '访问来源',
						type: 'pie',
						hoverAnimation: false,
						radius: ['100%', '80%'],
						center: ['50%', '50%'],
						label: {
							normal: {
								show: false,
								position: 'center'
							}
						},
						data: [{
							value: this.use
						}, {
							value: this.part
						}],
					}]
				};
				// 4.传入数据
				this.consumeNum.setOption(option);
			},
		},
		//图表实例
		mounted() {
			// this.addStore();
			// setInterval(() => {
			// 	this.expend();
			// 	this.order()
			// }, 800)
		},
		created() {
			const that = this;
			// that.total();
			
		},
		destroyed() {
			clearInterval(this.myVar);
		},
	};
</script>


<style scoped lang="scss">
	@import "./index.scss";
</style>
