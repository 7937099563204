<template>
	<div class="wrap">
		<dv-full-screen-container class="bg" >
			<div class="top">
				<topHeard></topHeard>
			</div>
			<div class="space-btw" v-if="login">
				<div class="left">
					<leftModel :today_income="today_income" :today_order_num="today_order_num"
						:today_order_pay_user_num="today_order_pay_user_num" :allPayNum="allPayNum"
						:allPaySum="allPaySum" :shop_all="shop_all"></leftModel>
				</div>
				<div class="center">
					<center-model :allPaySum="allPaySum" :shop_all="shop_all" :todayNewUser="todayNewUser"
						:currentUser="currentUser"></center-model>
				</div>
				<div class="right">
					<right-model></right-model>
				</div>
			</div>
      <div class="login" v-if="!login">
        <div class="login_x"></div>
        <div class="login_x login_x2"></div>
        <div class="h2">旅享生活平台登录页</div>
        <div class="h3">用户登录</div>
        <div class="login_in">
          <img src="@/static/username.png" class="" mode="widthFix" />
          <input  v-model="username" placeholder="请输入账号" />
        </div>
        <div class="login_in">
          <img src="@/static/password.png" class="" mode="widthFix" />
          <input type="password" v-model="password" placeholder="请输入密码" />
        </div>
        <div class="login_niu" @click="Login_niu">登录</div>
      </div>
		</dv-full-screen-container>

	</div>
</template>

<script>
	import topHeard from '@/components/topHeard/index.vue';
	import leftModel from '@/components/leftModel/index.vue';
	import centerModel from '@/components/centerModel/index.vue';
	import rightModel from '@/components/rightModel/index.vue';
	import * as totalApi from '../../api/screen.js'
  import { mapActions } from 'vuex'
	export default {

		components: {
			topHeard,
			leftModel,
			centerModel,
			rightModel
		},
		data() {
			return {
        username:'',
        password:'',
        login:true,
				total: '0.00',
				shop_all: 0, //门店总数
				allPayNum: 0, //总订单数
				allPaySum: 0, //总收款
				today_income: 0, //今日收入
				today_order_num: 0, //今日订单数量
				today_order_pay_user_num: 0, //今日支付人数
				todayNewUser: 0, //今日新增
				currentUser: 0, //当前会员总数
			}
		},
		created() {
			const that = this;
			that.getToday();
			that.getUser();
			that.getStore();
			that.myVar = setInterval(() => {
				that.getToday();
				that.getUser();
				that.getStore();
			}, 200000)
			setTimeout(() => {
				that.refresh()
			},43200000)
		},
		methods: {
      ...mapActions(['Login']),
      Login_niu(){
        if(this.username==''){this.$message.error("请输入账号"); return}
        if(this.password==''){this.$message.error("请输入密码"); return}
        this.Login({
          username:this.username,
          password:this.password,
        })
        .then(res => this.loginSuccess(res))
      },
      loginSuccess(res){
        if(res.status!=200){
          this.$message.error(res.message)
        }else{
          window.location.reload();
        }
      },
			refresh() {
			  window.location.reload();
			},
			getToday() {
				totalApi.showData({
						name: 'today_order_statistics_new'
					})
					.then(res => {
            if (res.status==401){
              this.login=false
              return
            }
            this.login=true
						let data = res.data.data;
						let func = (value) => {
							let count = parseInt(value).toString();
							if (count.length > 4 && count.length < 9) {
								return `${(count / 10000).toFixed(2)}万`;
							} else if (count.length >= 9) {
								return `${(count / 100000000).toFixed(2)}亿`;
							} else {
								return value
							}
						}
						this.allPayNum = data.all_pay_num; //总订单数
						this.allPaySum = data.all_pay_sum; //总收款
						this.today_income = func(data.today_income);
						this.today_order_num = func(data.today_order_num);
						this.today_order_pay_user_num = func(data.today_order_pay_user_num);
					})
			},
			getUser() {
				totalApi.showData({
						name: 'user'
					})
					.then(res => {
						let data = res.data.data;
						this.todayNewUser = data.today_new_user;
						this.currentUser = data.all_user_count;
					})
			},
			getStore() {
				// 门店总数
				totalApi.showData({
					name: 'shop_all'
				})
				.then(res => {
					let data = res.data.data;
					this.shop_all = data.shop_all;
				})
			}
		},
		mounted() {},
	}
</script>

<style scoped lang="scss">
	@import "./index.scss";
</style>
