<template>
    <div class="wrap">
		<div class="box">
			<div class="bar">
				<div class="bar-title">{{name[0]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#FF903F',width: values[0]}" ></div>
				</div>
				<div class="percentage">{{values[0]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[1]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#F5D362',width: values[1]}"></div>
				</div>
				<div class="percentage">{{values[1]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[2]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#1876F1',width: values[2]}"></div>
				</div>
				<div class="percentage">{{values[2]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[3]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#29CC8D',width: values[3]}"></div>
				</div>
				<div class="percentage">{{values[3]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[4]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#F46A96',width: values[4]}"></div>
				</div>
				<div class="percentage">{{values[4]}}</div>
			</div>
		</div>
		<div class="box">
			<div class="bar">
				<div class="bar-title">{{name[5]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#D316F5',width: values[5]}"></div>
				</div>
				<div class="percentage">{{values[5]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[6]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#FF903F',width: values[6]}"></div>
				</div>
				<div class="percentage">{{values[6]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[7]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#3FD199',width: values[7]}"></div>
				</div>
				<div class="percentage">{{values[7]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[8]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#694BFF',width: values[8]}"></div>
				</div>
				<div class="percentage">{{values[8]}}</div>
			</div>
			<div class="bar">
				<div class="bar-title">{{name[9]}}</div>
				<div class="progress-bar">
					<div class="progress-bg" :style="{background: '#0EFCFF',width: values[9]}"></div>
				</div>
				<div class="percentage">{{values[9]}}</div>
			</div>
		</div>
    </div>
</template>

<script>
	import * as typeApi from '../../api/screen.js'
export default {
    data() {
        return {
			name: [],
			values: []
        }
    },
	created() {
		const that = this;
		that.getType()
	},
    methods: {
		getType() {
			typeApi.showData({name:'shop_category_list'})
			.then(res => {
				let category_list = res.data.category_list;
				this.name = Object.keys(category_list);
				this.values = Object.values(category_list);
			})
		}
    },
    mounted() {
    },
}
</script>

<style scoped lang="scss">
	@import "./index.scss";
</style>