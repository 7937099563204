<template>
	<div class="content">
		<div class="title-box">
			<p class="f-w">消费用户占比</p>
			<p>Proportion of payment</p>
		</div>
		<div class="annular">
			<dv-active-ring-chart :config="config" style="width:17.5rem;height:17.5rem;" />
			<div class="colorTips">
				<div class="left">
					<div class="palette">
						<p class="size" style="background: #FFDB64;"></p><span class="text-c">女性</span>
					</div>
					<div class="palette">
						<p class="size" style="background: #29CC8D;"></p><span class="text-c">男性</span>
					</div>
					<div class="palette">
						<p class="size" style="background: #1876F1;"></p><span class="text-c">25岁以下
</span>
					</div>
				</div>
				<div class="right">
					<div class="palette">
						<p class="size" style="background: #694BFF;"></p><span class="text-c">25-45岁</span>
					</div>
					<div class="palette">
						<p class="size" style="background: #F46A96;"></p><span class="text-c">45-60岁
</span>
					</div>
					<div class="palette">
						<p class="size" style="background: #FF903F;"></p><span class="text-c">60岁以上
</span>
					</div>
				</div>
			</div>
		</div>
		<div class="title-box">
			<p class="f-w">新增门店列表</p>
			<p>New Store List</p>
		</div>
		<div class="add-store">
			<dv-scroll-board ref="scrollBoard" :config="storeList" style="width:32.375rem;height:14.375rem" />
		</div>
		<div class="title-box">
			<p class="f-w">实时消费列表</p>
			<p>Real time consumption</p>
		</div>
		<div class="expense">
			<dv-scroll-board ref="scrollList" :config="consume" style="width:32.375rem;height:14.375rem" />
		</div>
	</div>
</template>

<script>
	import * as paymentApi from '../../api/screen.js';
	export default {
		props: {
			currentUser: {
				type: [String, Number],
				default: 0
			}
		},
		data() {
			return {
				config: {
					addPercent: true,
					showOriginValue: true,
					radius: '60%',
					activeRadius: '65%',
					animationFrame: 60,
					data: [],
					color: ['#FFDB64', '#29CC8D', '#1876F1', '#694BFF', '#F46A96', '#FF903F'],
					lineWidth: 15,
					digitalFlopStyle: {
						fontSize: 22,
						fill: '#fff',
					},
				},
				storeList: {
					header: ['新增门店名称', '门店类型', '门店地区'],
					data: [],
					headerBGC: 'transparent',
					oddRowBGC: 'transparent',
					evenRowBGC: 'transparent',
					rowNum: 4,
				},
				consume: {
					header: ['用户UID', '支付方式', '支付金额', '支付时间'],
					data: [],
					headerBGC: 'transparent',
					oddRowBGC: 'transparent',
					evenRowBGC: 'transparent',
					rowNum: 4,
					columnWidth: [130, 115, 120, 180]
				},
			}
		},
		created() {
			const that = this;
			setTimeout(() => {
				that.getUsertype();
			},600)
			that.getStorelist();
			that.getOrderlist();
			that.myVar = setInterval(() => {
				that.getStorelist();
				that.getOrderlist();
			}, 240000)
		},
		methods: {
			getUsertype() {
				paymentApi.showData({
						name: 'proportionOfPaymentNew'
					})
					.then(res => {
						let data = res.data.list;
						let list = Object.entries(data);
						let arr = null;
						list.map(item => {
							arr = {
								name: item[0],
								value: parseFloat(item[1])
								// value: item[1]
							}
							this.config.data.push(arr)
						})
						this.config = {
							...this.config
						};
					})
			},
			getStorelist() {
				paymentApi.showData({
						name: 'shop_top_list_new'
					})
					.then(res => {
						let data = res.data.data;
						let list = [];
						let arr = null;
						data.map(item => {
							let cityName = item.cityName.length >= 4 ? item.cityName.substr(0, 3) + '...': item.cityName;
							arr = [`${item.shop_name}`,
								`<span style=\"color:#F51640;\">${item.type_name}</span>`,
								`${cityName + '-' + item.name}`
							]
							list.push(arr);
						})
						this.$refs.scrollBoard.updateRows(list)
					})
			},
			getOrderlist() {
				paymentApi.showData({
						name: 'order_list'
					})
					.then(res => {
						let data = res.data.list;
						let order = [];
						let orderArr = null;
						data.map(item => {
							orderArr = [`${item.pay_uid}`,
								`<span style=\"color:#FF903F;\">${item.curren == 10 ? '余额支付' : item.curren == 20 ? '现金支付' : '积分支付'}</span>`,
								`${Number(item.amount).toFixed(2)}`,
								`${item.update_time}`
							]
							order.push(orderArr);
						})
						this.$refs.scrollList.updateRows(order)
					})
			}
		},
		destroyed() {
			clearInterval(this.myVar);
		},
		mounted() {},
	}
</script>

<style scoped lang="scss">
	@import "./index.scss";
</style>
