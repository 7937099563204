<template>
	<div class="content">
		<!-- 现金收款业务 -->
		<div class="title-box">
			<p class="f-w">现金收款业务</p>
			<p>Cash collection business</p>
		</div>
		<div class="chart-table">
			<div class="unit">单位:(十万)</div>
			<div class="echars">
				<div class="echart" id="mychart" :style="myChartStyle"></div>
			</div>
			<!-- 收款金额 -->
			<div class="frame">
				<div class="title">收款金额</div>
				<div class="today">
					<div class="box"></div>
					<div class="today-num">今日<span class="bigSize">{{today_income}}</span></div>
				</div>
				<div class="past">
					<div class="box"></div>
					<div class="past-num">昨日<span class="bigSize">{{yesterday_income}}</span></div>
				</div>
				<div class="triangle"></div>
			</div>
			<!-- 订单数量 -->
			<div class="order">
				<div class="title">订单数量</div>
				<div class="today">
					<div class="box"></div>
					<div class="today-num">今日<span class="bigSize">{{today_order_num}}</span></div>
				</div>
				<div class="past">
					<div class="box"></div>
					<div class="past-num">昨日 <span class="bigSize">{{yesterday_order_num}}</span></div>
				</div>
				<div class="triangle"></div>
			</div>
			<!-- 消费人数 -->
			<div class="consume">
				<div class="title">消费人数</div>
				<div class="today">
					<div class="box"></div>
					<div class="today-num">今日<span class="bigSize">{{today_order_pay_user_num}}</span></div>
				</div>
				<div class="past">
					<div class="box"></div>
					<div class="past-num">昨日<span class="bigSize">{{yesterday_pay_uid_num}}</span></div>
				</div>
				<div class="triangle"></div>
			</div>
		</div>
		<!-- 门店信息占比 -->
		<div class="title-box">
			<p class="f-w">门店信息占比(30天)</p>
			<p>Store information</p>
		</div>
		<div class="gdp">
			<store-info :allPayNum="allPayNum" :allPaySum="allPaySum" :shop_all="shop_all"></store-info>
		</div>
		<div class="title-box">
			<p class="f-w">新增会员数</p>
			<p>Number of new members</p>
		</div>
		<div class="add-member">
			<dv-scroll-board ref="scrollBoard" :config="config" style="width:32.375rem;height:13.75rem" />
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	import * as dateApi from '../../api/screen.js';
	import storeInfo from "../storeInfo/index.vue";
	export default {
		components: {
			storeInfo
		},
		props: {
			today_income: {
				type: [String, Number],
				default: 0
			},
			today_order_num: {
				type: [String, Number],
				default: 0
			},
			today_order_pay_user_num: {
				type: [String, Number],
				default: 0
			},
			allPayNum: {
				type: [String, Number],
				default: 0
			},
			allPaySum: {
				type: [String, Number],
				default: 0
			},
			shop_all: {
				type: [String, Number],
				default: 0
			}
		},
		data() {
			return {
				// today_income: 0, //今日收入
				// today_order_num: 0, //今日订单数量
				// today_order_pay_user_num: 0, //今日支付人数
				yesterday_income: 0, //昨日收入
				yesterday_order_num: 0, //昨日订单
				yesterday_pay_uid_num: 0, //昨日支付人数
				columnar: {
					today_income: 0,
					today_order_num: 0,
					today_order_pay_user_num: 0,
					yesterday_income: 0,
					yesterday_order_num: 0,
					yesterday_pay_uid_num: 0,
				},
				config: {
					header: ['新增会员UID', '会员昵称', '加入时间'],
					data: [],
					headerBGC: 'transparent',
					oddRowBGC: 'transparent',
					evenRowBGC: 'transparent',
					rowNum: 4,
				},
				myChart: {},
				myChartStyle: {
					float: "left",
					width: "100%",
					height: "17.875rem",
				}, //图表样式
			};
		},
		mounted() {
			this.initEcharts();
		},
		created() {
			const that = this;
			that.showInfos();
			that.getMember();
			//现金收款
			setInterval(() => {
				that.showInfos();
			},600000)
			//新增会员数
			setInterval(() => {
				that.getMember();
			},300000)
		},
		methods: {
			initEcharts() {
				// 柱条间距
				const option = {
					xAxis: {
						type: 'category',
						data: ["收款金额", "订单数量", "消费人数"],
						axisLabel: {
							interval: 0,
							textStyle: {
								fontSize: 18,
							},
							margin: 8
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#E6E6E6'
							}
						},
						axisTick: {
							show: false,
						}
					},
					yAxis: {
						type: 'value',
						max: 500,
						interval: 100,
						axisLabel: { //y轴的坐标文字
							show: true,
							textStyle: {
								color: '#fff', //文字的颜色
								fontSize: 12
							},

						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						splitLine: { //坐标在grid区域的分割线
							lineStyle: { //设置分割线的样式(图表横线颜色)
								color: ['#2F2B4B']
							}
						}
					},
					series: [{
							type: "bar",
							data: [300, 150, 250],
							barGap: "0%", // 两个柱子之间的距离相对于柱条宽度的百分比;
							barCategoryGap: "70%", // 每侧空余的距离相对于柱条宽度的百分比
							itemStyle: {
								color: "#FF903F",
								borderType: "dashed",
							}
						},
						{
							type: "bar",
							data: [350, 190, 300],
							itemStyle: {
								color: "#1876F1",
								borderType: "dashed",
							}
						}
					]
				};
				const myChart = echarts.init(document.getElementById("mychart"));
				myChart.setOption(option);
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});
			},

			// 现金收款业务
			showInfos() {
				dateApi.showData({
						name: 'yesterday_order_statistics_new'
					})
					.then(res => {
						let data = res.data.data;
						let func = (value) => {
							let count = parseInt(value).toString();
							if (count.length > 4 && count.length < 9) {
								return `${(count / 10000).toFixed(2)}万`;
							} else if (count.length >= 9) {
								return `${(count / 100000000).toFixed(2)}亿`;
							} else {
								return value
							}
						}
						this.yesterday_income = func(data.yesterday_income);
						this.yesterday_order_num = func(data.yesterday_order_num);
						this.yesterday_pay_uid_num = func(data.yesterday_pay_uid_num)
					})
					
			},
			getMember() {
				dateApi.showData({
					name: 'new_user'
				})
				.then(res => {
					let data = res.data.list;
					let list = [];
					let arr = null;
					data.map(item => {
						arr = [`${item.user_id}`, `<span style=\"color:#FF903F;\">${item.nick_name}</span>`, item.create_time];
						list.push(arr);
					})
					this.$refs.scrollBoard.updateRows(list)
				})
			}
		},
	}
</script>

<style scoped lang="scss">
	@import "./index.scss";
</style>
