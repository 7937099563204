<template>
	<div class="content">
		<div class="title">旅享生活累计消费总金额(元)</div>
		<div class="amount">
			<div class="num-item" v-for="(item,index) in list" :key="index"
				:class="[{'noColor' : item == ',' || item == '.'}]">{{item}}</div>
		</div>
		<div class="infos">
			<div class="modular" v-for="(item,index) in total" :key="index">
				<div class="iconImg">
					<img :src="item.img" />
				</div>
				<div class="infos-total">
					<div class="text">{{item.text}}</div>
					<div class="num" :style="{color: (index == 0 ? '#FF903F' : index == 1 ? '#F5D362' : '#0EFCFF')}">
						{{item.num}}
					</div>
				</div>
				<div class="boxfoot"></div>
			</div>
		</div>
		<div class="consume-type">
			<div class="title-box">
				<p class="f-w">消费类型占比</p>
				<p>Consumption type</p>
			</div>
			<consume-type></consume-type>
		</div>
	</div>
</template>

<script>
	import consumeType from '../consumeType/index.vue';
	import * as memberApi from '../../api/screen.js';
	export default {
		components: {
			consumeType
		},
		props: {
			allPaySum: {
				type: [String, Number],
				default: 0
			},
			shop_all: {
				type: [String, Number],
				default: 0
			},
			todayNewUser: {
				type: [String, Number],
				default: 0
			},
			currentUser: {
				type: [String, Number],
				default: 0
			}
		},
		data() {
			return {
				// list: [],
				total: [{
						img: 'https://pic.tralife.cn/10001/20221222/ad03802cf7a3651927e34ce2df66d95a.png',
						text: '今日新增会员数',
						num: 0
					},
					{
						img: 'https://pic.tralife.cn/10001/20221207/af224fd2c63e6031f2677a081a04b3c4.png',
						text: '当前会员总数',
						num: 0
					},
					{
						img: 'https://pic.tralife.cn/10001/20221207/10e18c43067527f30ac0b674654cc867.png',
						text: '当前门店总数',
						num: 0
					}
				]
			}
		},
		computed: {
			list() {
				let total = Number(this.allPaySum).toFixed(2).toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
				let data = total.toString();
				return data.split('');
			}
		},
		methods: {
			// getInfos() {
			// 	let total = Number(this.allPaySum).toFixed(2).toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
			// 	let data = total.toString();
			// 	this.list = data.split('');
			// 	console.log(this.allPaySum,'******')
			// },
			getMember() {
				this.total[0].num = this.todayNewUser;
				this.total[1].num = this.currentUser;
				this.total[2].num = this.shop_all;
			}
		},
		created() {
			const that = this;
			setTimeout(() => {
				// that.getInfos()
				that.getMember();
			}, 1300)
			that.myVar = setInterval(() => {
				that.getMember();
				// that.getInfos()
			}, 300000)
		},
		destroyed() {
			clearInterval(this.myVar);
		},
		mounted() {},
		onLoad() {},
	}
</script>

<style scoped lang="scss">
	@import "./index.scss";
</style>
